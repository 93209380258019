<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 表格 -->
    <el-table
      :data="tableRes.meiyuperformancelist"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        label="年度招生人数"
        prop="cyear"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        label="年度电脑招生人数"
        prop="ccomputer"
        align="center"
        width="170"
      ></el-table-column
      >.
      <el-table-column
        label="年度日语招生人数"
        prop="cjapanese"
        align="center"
        width="170"
      ></el-table-column>
      <el-table-column
        label="上月招生人数"
        prop="cpermonth"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        label="当月招生人数"
        prop="ccurmonth"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="昨天"
        prop="countpreday"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column
        label="今天"
        prop="counttoday"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="昨天电话量"
        prop="countpredaymobile"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="今天电话量"
        prop="counttodaymobile"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="昨天接待量"
        prop="countpredayreceive"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="今天接待量"
        prop="counttodayreceive"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="1月" prop="comjanuary" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="2月"
        prop="comfebruary"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="3月" prop="commarch" align="center" width="100">
      </el-table-column>
      <el-table-column label="4月" prop="comapril" align="center" width="100">
      </el-table-column>
      <el-table-column label="5月" prop="commay" align="center" width="100">
      </el-table-column>
      <el-table-column label="6月" prop="comjune" align="center" width="100">
      </el-table-column>
      <el-table-column label="7月" prop="comjuly" align="center" width="100">
      </el-table-column>
      <el-table-column label="8月" prop="comaugust" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="9月"
        prop="comseptember"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="10月"
        prop="comoctober"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="11月"
        prop="comnovember"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="12月"
        prop="comdecember"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="合计" prop="comtotal" align="center" width="100">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { techperformance_meiyuzhaosheng } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "meiyuzhaosheng",
  components: {},
  props: {},
  data() {
    return {
      searchForm: {},
      tableRes: {}, //列表接口
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    //列表查询 api
    getDataList() {
      myRequest({
        url: techperformance_meiyuzhaosheng,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>